<template>
  <div class="vg_wrapper">
    <el-card>
      <editHeader
        :isShow="isShow"
        :btn="btn"
        :strForm="myacForm"
        @openEdit="openEdit"
        @closeEdit="closeEdit"
        @submit="submit('myacForm')"/>
      <el-form ref="myacForm" :model="myacForm" :rules="rules" :disabled="isShow" label-width="120px" size="mini">
        <el-row>
          <el-col :md="8">
            <el-form-item label="账套编号" prop="acct_no">
              <el-input v-model="myacForm.acct_no" placeholder="账套编号" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8" :offset="4">
            <el-form-item label="账套名称" prop="acct_name">
              <el-input v-model="myacForm.acct_name" placeholder="账套名称" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="vd_button">
          <el-button type="primary" plain size="small" @click="addRow()">添加</el-button>
          <el-button type="danger" plain size="small" @click="delTeam()">删除</el-button>
        </div>
        <el-table ref="myacForm" :data="myacForm.acct_fctr_list" border class="vg_mt_16" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="48" align="center"></el-table-column>
          <el-table-column label="序号" width="48" align="center">
            <template slot-scope="scope">
              {{ scope.$index+1 }}
            </template>
          </el-table-column>
          <el-table-column>
            <template slot="header">
              <span class="vg_deep_red">工厂编号</span>
            </template>
            <template slot-scope="scope">
              <el-form-item label-width="0" :prop="'acct_fctr_list.'+scope.$index+'.acct_fctr_no'" :rules="[{required:true}]">
                <el-input v-model="myacForm.acct_fctr_list[scope.$index].acct_fctr_no" @input ="myacForm.acct_fctr_list[scope.$index].acct_fctr_no = helper.keepEngNum(myacForm.acct_fctr_list[scope.$index].acct_fctr_no)" maxlength="10" show-word-limit placeholder="请填写工厂编号（必填）"></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot="header">
              <span class="vg_deep_red">工厂名称</span>
            </template>
            <template slot-scope="scope">
              <el-form-item label-width="0" :prop="'acct_fctr_list.'+scope.$index+'.acct_fctr_name'" :rules="[{required:true}]">
                <el-input v-model="myacForm.acct_fctr_list[scope.$index].acct_fctr_name" maxlength="30" show-word-limit placeholder="请填写工厂名（必填）"></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot="header">
              <span class="vg_deep_red">工厂类型</span>
            </template>
            <template slot-scope="scope">
              <el-form-item label-width="0"  :rules="[{required:true}]">
                <el-select v-model="myacForm.acct_fctr_list[scope.$index].acct_fctr_type" placeholder="请选择工厂类型"  clearable>
                  <el-option label="加工厂" :value="1"></el-option>
                  <el-option label="面料厂" :value="2"></el-option>
                  <el-option label="配套厂" :value="3"></el-option>
                  <el-option label="辅料厂" :value="4"></el-option>
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="电话">
            <template slot-scope="scope">
              <el-form-item label-width="0" :prop="'acct_fctr_list.'+scope.$index+'.acct_fctr_phone'">
                <el-input maxlength="30" show-word-limit  v-model="myacForm.acct_fctr_list[scope.$index].acct_fctr_phone" placeholder="请填写电话号码"></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="地址">
            <template slot-scope="scope">
              <el-form-item label-width="0" :prop="'acct_fctr_list.'+scope.$index+'.acct_fctr_address'">
                <el-input  type="textarea" :rows="1"  v-model="myacForm.acct_fctr_list[scope.$index].acct_fctr_address" placeholder="请填写地址信息"></el-input>
              </el-form-item>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import {get,post} from "@api/request";
import {acctAPI} from "@api/modules/acct"
import editHeader from "@/views/component/editHeader";
export default {
  name: "MyacList",
  components: {
    editHeader
  },
  data() {
    return {
      myacForm: {
        acct_no:null,
        acct_name: '',
        acct_type:'',
        acct_fctr_list:[]
      },
      rules:{
        acct_no:[
          { required: true, trigger: 'blur' , message: ' '},
        ],
        acct_name:[
          { required: true, trigger: 'blur' , message: ' '},
        ],
        // acct_type:[
        //   { required: true, trigger: 'blur' , message: ' '},
        // ],
      },
      key: 0,
      acct_fctr_copy:[],
      selectionsList: [],
      isShow: true,
      btn:{},
      copyForm:[],
    }
  },
  created() {
    this.initData()
  },
  methods: {
    initData(){
      this.getMyacInfo();
    },
    //启用编辑
    openEdit(){
      this.isShow = false
    },
    //撤销编辑
    closeEdit(){
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        this.isShow = true
        this.initData()
        this.$message({
          type: 'info',
          message: '已撤销编辑!'
        });
      }).catch(()=>{})
    },
    //获取表单信息
    getMyacInfo() {
      get(acctAPI.getMyAcct)
          .then(res => {
            if (res.data.code === 0) {
              this.myacForm = res.data.data.form;
              this.btn = res.data.data.btn
              this.myacForm.acct_fctr_list.forEach(item=>{
                item.k = this.key;
                this.key++;
              })
              this.acct_fctr_copy = this.myacForm.acct_fctr_list;
              this.copyForm = Object.assign(this.copyForm,this.myacForm.acct_fctr_list)
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message: mg, type: tp});
            }
          })
          .catch(res => {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message: mg, type: tp});
          })
    },
    //添加
    addRow() {
      let item = {
        // param1: '',
        delete_time: 0,
        k: this.key
      }
      this.key = this.key +1
      this.myacForm.acct_fctr_list.push(item);
      this.selectionsList = [];
    },
    //多选
    handleSelectionChange(selection) {
      this.selectionsList = [];
      this.selectionsList = selection
    },
    //多选删除
    delTeam() {
      for(let i = 0 ; i < this.selectionsList.length; i++) {
        this.myacForm.acct_fctr_list.forEach((item)=>{
          if(item.k === this.selectionsList[i].k) {
            let currentIndex = this.myacForm.acct_fctr_list.indexOf(item);
            this.myacForm.acct_fctr_list.splice(currentIndex,1);
          }
        });
      }
    },
    //提交表单
    submit(formName){
      this.$confirm('确定提交？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.saveInfo()
          } else {
            console.log('error submit!');
            return false;
          }
        });
      }).catch(()=>{})
    },
    // 保存
    saveInfo() {
      let delete_time = this.helper.toTimeVal();
      let myacForm = {};
      let myacFi = [];
      myacForm = Object.assign(myacForm,this.myacForm);
      myacFi = Object.assign(myacFi,this.acct_fctr_copy);
      for(let i=this.copyForm.length-1;i>=0;i--){
        if(myacFi.indexOf(this.copyForm[i]) !== -1){
          this.copyForm.splice(i,1)
        }
      }
      for(let i=this.copyForm.length-1;i>=0;i--){
        this.copyForm[i].delete_time = delete_time;
      }
      myacFi = myacFi.concat(this.copyForm);
      myacForm.acct_fctr_list = myacFi;
      post(acctAPI.editMyAcct,myacForm)
          .then(res => {
            if(res.data.code === 0){
              this.$message({
                type:'success',
                message:'保存成功'
              })
              this.initData()
              this.isShow = true
            } else if(res.data.code === 7){
              this.$message({
                type:'error',
                message:'工厂编号重复'
              })
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message: mg, type: tp});
              this.initData()
            }
          }).catch((res)=>{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message:mg,type:tp});
          })
    },
  }
}
</script>

<style scoped lang="scss">
.vd_button{
  margin-top: 32px;
  border-top: 1px dashed $color-secondary-text;
  padding-top: 16px;
}
</style>